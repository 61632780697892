// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-product-tags-jsx": () => import("../src/pages/productTags.jsx" /* webpackChunkName: "component---src-pages-product-tags-jsx" */),
  "component---src-pages-blog-jsx": () => import("../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-templates-product-page-tem-js": () => import("../src/templates/productPageTem.js" /* webpackChunkName: "component---src-templates-product-page-tem-js" */),
  "component---src-templates-blog-template-js": () => import("../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-customer-care-jsx": () => import("../src/pages/customer-care.jsx" /* webpackChunkName: "component---src-pages-customer-care-jsx" */),
  "component---src-pages-help-js": () => import("../src/pages/help.js" /* webpackChunkName: "component---src-pages-help-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lookbook-js": () => import("../src/pages/lookbook.js" /* webpackChunkName: "component---src-pages-lookbook-js" */),
  "component---src-pages-oldblog-tags-jsx": () => import("../src/pages/oldblogTags.jsx" /* webpackChunkName: "component---src-pages-oldblog-tags-jsx" */),
  "component---src-pages-sizeguides-jsx": () => import("../src/pages/sizeguides.jsx" /* webpackChunkName: "component---src-pages-sizeguides-jsx" */)
}

